import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import AxiosApiClient from '../../utils/AxiosApiClient';
import AuthUtility from './AuthUtility';
import SingleSignOn from './SingleSignOn';
import LoadingSpinner from '../LoadingSpinner';

import swal from 'sweetalert';

const error_style = 'font-raleway font-small font-weight-500 txt-000 bg-light-red p-5';

function Login() {

	const navHistory = useNavigate();

	// using hooks
	const [isLoading, setIsLoading] = React.useState(false);
	const [loginInput, setLogin] = useState({
		email: localStorage.getItem('auth_email') ? localStorage.getItem('auth_email') : '',
		password: localStorage.getItem('password') ? localStorage.getItem('password') : '',
		errorList: [],
		errorStyle: [],
	});

	const [isChecked, setIsChecked] = useState(localStorage.getItem('remember_me') && localStorage.getItem('remember_me') === 'true' ? true : false);

	const handleInput = (event) => {
		event.persist();

		if (event.target.name !== 'rememberMe') {
			setLogin({ ...loginInput, [event.target.name]: event.target.value });
		}

		//remove the target error message no matter the new input, it will be validated on the server
		if (loginInput.errorList.hasOwnProperty(event.target.name)) {
			delete loginInput.errorList[event.target.name];
			delete loginInput.errorStyle[event.target.name];
		}
	}

	const loginSubmit = (event) => {
		event.preventDefault();

		setIsLoading(true);

		//values sent to api
		const payload = {
			email: loginInput.email,
			password: loginInput.password,
		}

		var auth_api = 'phpLaravel';
		if (localStorage.getItem('auth_api') && localStorage.getItem('auth_api') != '') {
			auth_api = localStorage.getItem('auth_api');
		}

		const fetchData = async () => {
			try {
				const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
				await getBearerToken();

				const response = await makeRequestWithHeaders('post', '/api/login', payload, {});

				setApiData(response);
			} catch (error) {
				handleApiError(error);
			}
		};

		fetchData();
	}
	function setApiData(response) {

		if (response && response.data) {
			if (response.data.status === 200) {//HTTP_OK
				console.log('loginSubmit: ', response.data.message);

				AuthUtility.setAuthData(response.data.auth_first_name, response.data.auth_last_name, response.data.auth_email, response.data.auth_pay_type,
					response.data.auth_token, response.data.auth_role, loginInput.password, isChecked);
				//setAuthData: (first_name, last_name, email, pay_type, token, role, password, remember_me) => {

				if (response.data.payment_paid) {
					AuthUtility.setSubscriptionData(response.data.payment_paid.plan, response.data.payment_paid.start_date, response.data.payment_paid.end_date, response.data.payment_paid.subscription_is_valid);
					//setSubscriptionData: (subscription_plan, subscription_start_date, subscription_end_date, subscription_is_valid) => {
				}

				//redirect to proper dashboard based on role
				if (response.data.auth_role === 'admin') {
					navHistory('/admin/dashboard');
				} else if (response.data.auth_role === 'member') {
					navHistory('/member/account');
				} else {
					navHistory('/');
				}

			} else if (response.data.status === 401) {//HTTP_UNAUTHORIZED
				console.log('loginSubmit error: ', response.data.message + ' back-end api call error');

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();

				swal("Warning", response.data.message, "warning");

			} else if (response.data.status === 404) {//HTTP_NOT_FOUND
				console.log('loginSubmit error: ', response.data.message + ' back-end api call error');

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();

				swal("Warning", response.data.message, "warning");

			} else if (response.data.status === 800) {//HTTP_FORM_VALIDATION_FAILED

				var errorStyleTemp = JSON.parse(JSON.stringify(response.data.validation_errors));
				Object.keys(errorStyleTemp).map((key) => (
					errorStyleTemp[key] = error_style
				));

				//validation errors mapped to input fields
				setLogin({ ...loginInput, errorList: response.data.validation_errors, errorStyle: errorStyleTemp });

			} else {//more errors
			}
		}

		setIsLoading(false);
	}
	function handleApiError(error) {
		console.log('loginSubmit error: ', error + ' back-end api call error');

		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();

		setIsLoading(false);

		swal("Error", error, "error");
	}

	const handleCheckboxChange = (event) => {
		const { checked } = event.target;
		setIsChecked(checked);
		localStorage.setItem('remember_me', checked);
	};

	return (
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">

			<div className="panel medium pt-20">
				<div className="grid-x bg-fff b1-ddd p-20l-10s">
					<div className="large-12 medium-12 small-12 cell text-center">
						<div className="font-raleway font-x-large font-weight-700 txt-333 underline uppercase">Login</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 text-center p-10">Not registered with us yet? <Link to="/register" className="font-raleway font-medium font-weight-600">Register</Link></div>

						<form onSubmit={loginSubmit}>
							<div className="grid-x">
								<div className="large-6 medium-6 small-12 cell text-left pr-5l-0s">
									<div className={loginInput.errorStyle.email}>{loginInput.errorList.email}</div>
									<div className="input-group">
										<span className="input-group-label"><span className="fas fa-envelope txt-555"></span></span>
										<input type="text" name="email" onChange={handleInput} value={loginInput.email} className="input-group-field" placeholder="name@example.com" />
									</div>
								</div>
								<div className="large-6 medium-6 small-12 cell text-left pl-5l-0s">
									<div className={loginInput.errorStyle.password}>{loginInput.errorList.password}</div>
									<div className="input-group">
										<span className="input-group-label"><span className="fas fa-lock txt-555"></span></span>
										<input type="password" name="password" onChange={handleInput} value={loginInput.password} className="input-group-field" placeholder="Enter Password" />
									</div>
								</div>
								<div className="large-6 medium-6 small-7 cell text-left">
									<input type="checkbox" name="rememberMe" id="remember_me" value="1" checked={isChecked} onChange={handleCheckboxChange} />
									<label htmlFor="remember_me" className="checkbox-label"><span className="checkbox"></span><span className="message font-raleway font-medium font-weight-600">&nbsp;&nbsp;&nbsp;Remember<span className="hide-for-small-only"> Me</span></span></label>
								</div>
								<div className="large-6 medium-6 small-5 cell text-right">
									<div className="clearfix">
										<button type="submit" className="button width-125px-100px right">Login</button>
										{isLoading &&
											<span className="right pr-10">
												<LoadingSpinner paddingClass="none" sizeClass="none" />
											</span>
										}
									</div>
								</div>
							</div>
							<div className="grid-x pt-10">
								<div className="large-12 medium-12 small-12 cell text-center bg-fafafa b1-eee p-10 ">
									<Link to="/forgot_password" className="font-raleway font-medium font-weight-600">Forgot Password?</Link>
								</div>
							</div>
						</form>

					</div>
				</div>
			</div>
			<SingleSignOn />
		</div>
	);
}

export default Login;
